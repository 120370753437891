import loadable from '@loadable/component'
import React from 'react'
import {Route, StaticRouter} from 'react-router'
import PropTypes from 'prop-types'

const Callback = loadable(() => import('./callback'))

const Launcher = loadable(() => import('./launcher'))

const Disclaimer = loadable(() => import('./disclaimer'))

export function App(props) {
  return (
    <StaticRouter location={props.location}>
      <Route
        path="/callbacks/:type"
        component={({match}) => <Callback type={match.params.type} />}
      />
      <Route path="/games/:id" component={Launcher} />
      <Route path="/games/:id" component={Disclaimer} />
      <Route path="/pages/:provider/:name" component={Launcher} />
    </StaticRouter>
  )
}

App.propTypes = {
  location: PropTypes.string,
}
