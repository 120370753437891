export const BALANCE_UPDATED = '@rushplay/gamer/BALANCE_UPDATED'
export const BET_HISTORY_OPENED = '@rushplay/gamer/BET_HISTORY_OPENED'
export const CLASSIC_MODE_OPENED = '@rushplay/gamer/CLASSIC_MODE_OPENED'
export const COOKIE_UPDATED = '@rushplay/gamer/COOKIE_UPDATED'
export const GAME_CLOSED = '@rushplay/gamer/GAME_CLOSED'
export const GAMEPLAY_PAUSED = '@rushplay/gamer/GAMEPLAY_PAUSED'
export const GAMEPLAY_RESUMED = '@rushplay/gamer/GAMEPLAY_RESUMED'
export const INITIALIZED = '@rushplay/gamer/INITIALIZED'
export const LIMITS_OPENED = '@rushplay/gamer/LIMITS_OPENED'
export const LOGIN_OPENED = '@rushplay/gamer/LOGIN_OPENED'
export const REALITY_CHECK_RESET = '@rushplay/gamer/REALITY_CHECK_RESET'
export const REALITY_CHECK_SCHEDULED = '@rushplay/gamer/REALITY_CHECK_SCHEDULED'
export const REALITY_CHECK_TRIGGERED = '@rushplay/gamer/REALITY_CHECK_TRIGGERED'
export const WALLET_OPENED = '@rushplay/gamer/WALLET_OPENED'
