import * as types from './types'

export function closeGame() {
  return {type: types.GAME_CLOSED}
}

export function initialize(options) {
  return {
    type: types.INITIALIZED,
    payload: {
      game: {
        blitzProperties: options.game.blitzProperties,
        displayProvider: options.game.displayProvider,
        launchMethod: options.game.launchMethod,
        launchOptions: options.game.launchOptions,
        launchUrl: options.game.launchUrl,
        provider: options.game.provider,
        width: options.game.width,
        height: options.game.height,
        forceAspectRatio: options.game.forceAspectRatio,
      },
      mode: options.mode,
      realityCheckPeriod: options.realityCheckPeriod,
      realityCheckResetTrigger: options.realityCheckResetTrigger,
      token: options.token,
    },
  }
}

export function openBetHistory() {
  return {type: types.BET_HISTORY_OPENED}
}

export function openClassicMode() {
  return {type: types.CLASSIC_MODE_OPENED}
}

export function openLimits() {
  return {type: types.LIMITS_OPENED}
}

export function openLogin() {
  return {type: types.LOGIN_OPENED}
}

export function openWallet() {
  return {type: types.WALLET_OPENED}
}

export function resetRealityCheck() {
  return {type: types.REALITY_CHECK_RESET}
}

export function scheduleRealityCheck(timestamp) {
  return {
    type: types.REALITY_CHECK_SCHEDULED,
    payload: timestamp,
  }
}

export function triggerRealityCheck() {
  return {
    type: types.REALITY_CHECK_TRIGGERED,
  }
}

export function updateBalance(balance) {
  return {
    type: types.BALANCE_UPDATED,
    payload: balance,
  }
}

export function updateCookie(values) {
  return {
    type: types.COOKIE_UPDATED,
    payload: values,
  }
}
